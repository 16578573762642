@import '../../../../styles/basics';
@import '../../../../styles/mixins/buttons';

.bb-featured-event {
  &:global(.bb) {
    @include bb-standalone-spacing-new();
  }

  :global {
    .row {
      min-height: 260px;

      [class*='col-'] {
        margin-bottom: 0;
        display: flex;
        align-items: stretch;
      }

      @include media-breakpoint-up(md) {
        flex-direction: row-reverse;
      }
    }

    a {
      text-decoration: inherit;

      .image {
        width: 100%;
        min-height: 180px;
        background-size: cover;
        background-position: center center;
        flex: 0 0 auto;

        @include media-breakpoint-up(md) {
          width: calc(100% + 12px);
          margin-left: -12px;
        }
        // Enable slight hover animation on desktop.
        @include media-breakpoint-up(lg) {
          background-size: 100%;
          transition: background-size 0.3s;
        }
      }

      [data-component^="button-"] {
        margin-top: 16px;
        margin-bottom: 0;
        margin-right: 0;
      }

      &:hover,
      &:focus-visible {
        .image {
          @include media-breakpoint-up(lg) {
            background-size: 101%;
          }
        }
        [data-component^="button-"] {
          @include button-primary-hover;
          @include button-with-arrow-hover;
        }
      }
    }

    .content {
      background: $turquoise-600;
      color: $white;
      text-align: center;
      width: 100%;
      flex: 0 0 auto;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-up(md) {
        padding: 32px 40px;
        width: calc(100% + 12px);
        margin-right: -12px;
      }

      .label {
        margin-bottom: 16px;
        width: 100%;
      }

      .title {
        color: inherit;
        text-transform: uppercase;
      }

      .description {
        color: inherit;
      }

    }
  }
}
